var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"gray-background-no",attrs:{"md":"12"}},[_c('table',{staticClass:"width-100"},[_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500",staticStyle:{"width":"100px"}},[_vm._v(" Full name ")]),_c('td',{staticClass:"pb-2 font-size-18 text-capitalize"},[(_vm.engineer.emergency_contact_name)?[_vm._v(_vm._s(_vm.engineer.emergency_contact_name))]:[_c('em',{staticClass:"text-muted"},[_vm._v("no name")])]],2)]),_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500"},[_vm._v(" Mobile No ")]),_c('td',{staticClass:"font-size-18 text-capitalize"},[(_vm.engineer.emergency_contact_number)?[_vm._v(_vm._s(_vm.engineer.emergency_contact_number))]:[_c('em',{staticClass:"text-muted"},[_vm._v("no mobile number")])]],2)]),_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500"},[_vm._v("Relation")]),_c('td',{staticClass:"font-size-18 text-capitalize"},[(_vm.engineer.emergency_contact_relation)?[_vm._v(_vm._s(_vm.engineer.emergency_contact_relation))]:[_c('em',{staticClass:"text-muted"},[_vm._v("no relation")])]],2)]),_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500"},[_vm._v("Email")]),_c('td',{staticClass:"font-size-18"},[(_vm.engineer.emergency_contact_email)?[_vm._v(_vm._s(_vm.engineer.emergency_contact_email))]:[_c('em',{staticClass:"text-muted"},[_vm._v("no email")])]],2)]),_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500"},[_vm._v("Address")]),_c('td',{staticClass:"font-size-18 text-capitalize"},[(
              _vm.engineer.emergency_contact_unit_no ||
              _vm.engineer.emergency_contact_street_1 ||
              _vm.engineer.emergency_contact_street_2 ||
              _vm.engineer.emergency_contact_postal_code
            )?[_vm._v(" "+_vm._s(_vm.getAddress)+" ")]:[_c('em',{staticClass:"text-muted"},[_vm._v("no address")])]],2)]),(
          _vm.engineer?.emergency_contact_postal_code &&
          _vm.engineer.emergency_contact_postal_code != 'nil' &&
          _vm.engineer.emergency_contact_postal_code != 'Nil' &&
          _vm.engineer.emergency_contact_postal_code != 'NIL' &&
          _vm.engineer.emergency_contact_postal_code != '000000'
        )?_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500"},[_vm._v("Country")]),_c('td',{staticClass:"font-size-18 text-capitalize"},[(_vm.engineer.emergency_country_name)?[_vm._v(_vm._s(_vm.engineer.emergency_country_name)+" ")]:[_c('em',{staticClass:"text-muted"},[_vm._v("no country")])]],2)]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }