import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import {
  GET,
  POST,
  PATCH,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";

export default {
  data() {
    return {
      engineerTab: "personal-info",
      pageLoading: false,
      updateEngineer: false,
      engineer: null,
      //  teamId: null,
      engineerArr: {},
      data: {
        title: "mr",
        first_name: null,
        color: null,
        profile_logo: null,
        last_name: null,
        skills: null,
        display_name: null,
        primary_email: null,
        primary_phone: null,
        probation_type: null,
        probation_end: null,
        probation_start: null,
        probation_month_number: null,
        emergency_contact_name: null,
        emergency_contact_number: null,
        emergency_contact_relation: null,
        emergency_contact_unit_no: null,
        emergency_contact_street_1: null,
        emergency_contact_street_2: null,
        emergency_contact_postal_code: null,
        emergency_contact_email: null,
        address_line_1: null,
        address_line_2: null,
        unit_number: null,
        postal_code: null,
        country_name: null,
        emergency_country_name: null,
        country: null,
        state: null,
        city: null,
        leave: null,
        remarks: null,
        can_login: 1,
        is_tl: 0,
        is_timestamp: 0,
        engineer_leaves: {},
        allow_leave: 0,
        designation: 0,
        role: 0,
      },
    };
  },
  methods: {
    getEngineer() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "engineer/" + _this.engineer })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    getTeamSingle() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "team/" + _this.teamId })
            .then((response) => {
              // console.log(response,"response");
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    onSubmit() {
      const _this = this;
      if (!_this.$refs.engineerForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      const formData = {
        title: _this.data.title,
        first_name: _this.data.first_name,
        color: _this.data.color,
        profile_logo: _this.data.profile_logo
          ? _this.data.profile_logo.id
          : null,
        last_name: _this.data.last_name,
        skills: _this.data.skills,
        display_name: _this.data.display_name,
        primary_email: _this.data.primary_email,
        primary_phone: _this.data.primary_phone,
        probation_type: _this.data.probation_type,
        probation_month_number: _this.data.probation_month_number,
        probation_start: _this.data.probation_start,
        probation_end: _this.data.probation_end,
        emergency_contact_name: _this.data.emergency_contact_name,
        emergency_contact_number: _this.data.emergency_contact_number,
        emergency_contact_relation: _this.data.emergency_contact_relation,
        emergency_contact_email: _this.data.emergency_contact_email,
        emergency_contact_unit_no: _this.data.emergency_contact_unit_no,
        emergency_contact_street_1: _this.data.emergency_contact_street_1,
        emergency_contact_street_2: _this.data.emergency_contact_street_2,
        emergency_contact_postal_code: _this.data.emergency_contact_postal_code,
        address_line_1: _this.data.address_line_1,
        address_line_2: _this.data.address_line_2,
        unit_number: _this.data.unit_number,
        postal_code: _this.data.postal_code,
        country_name: _this.data.country_name,
        emergency_country_name: _this.data.emergency_country_name,
        country: _this.data.country,
        state: _this.data.state,
        city: _this.data.city,
        leave: _this.data.leave,
        allow_leave: _this.data.allow_leave,
        engineer_leaves: _this.data.engineer_leaves,
        remarks: _this.data.remarks,
        can_login: _this.data.can_login,
        is_tl: _this.data.is_tl,
        is_timestamp: _this.data.is_timestamp,
        designation: _this.data.designation,
        role: _this.data.role,
      };
      //console.log(formData,"formData");
      let REQUEST_TYPE = POST;
      let REQUEST_URL = "engineer";
      if (
        _this.$route.name == "admin.engineer.update" ||
        _this.$route.name == "engineer.engineer.update"
      ) {
        REQUEST_TYPE = PATCH;
        REQUEST_URL = "engineer/" + _this.$route.params.id;
      }

      _this.$store
        .dispatch(REQUEST_TYPE, { url: REQUEST_URL, data: formData })
        .then((response) => {
          _this.engineer = response.data.id;
          _this.engineerArr = response.data;
          _this.$router.replace(
            _this.getDefaultRoute("engineer.detail", {
              params: { id: _this.engineer },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  beforeDestroy() {
    /*EngineerEventBus.$off("update:personal-info");
    EngineerEventBus.$off("update:emergency-contact");
    EngineerEventBus.$off("update:address");
    EngineerEventBus.$off("update:color");*/
  },
  created() {
    const _this = this;
    EngineerEventBus.$on("update:personal-info", (argument) => {
      _this.data.profile_logo = argument.profile_image;
      _this.data.title = argument.title || "mr";
      _this.data.first_name = argument.first_name;
      _this.data.last_name = argument.last_name;
      _this.data.skills = argument.skills;
      _this.data.role = argument.role;
      _this.data.display_name = argument.display_name;
      _this.data.designation = argument.designation;
      _this.data.primary_email = argument.primary_email;
      _this.data.primary_phone = argument.primary_phone;
      _this.data.probation_type = argument.probation_type;
      _this.data.probation_month_number = argument.probation_month_number;
      _this.data.probation_end = argument.probation_end;
      _this.data.probation_start = argument.probation_start;

      _this.data.engineer_leaves = argument.engineerLeaves;
      _this.data.allow_leave = argument.allow_leave;
      _this.data.leave = argument.leave;
      _this.data.can_login = _this.lodash.toSafeInteger(argument.can_login) || 0;
      _this.data.is_tl = argument.is_tl;
      _this.data.is_timestamp = argument.is_timestamp;
    
    });
    

    EngineerEventBus.$on("update:emergency-contact", (argument) => {
      _this.data.emergency_contact_name = argument.name;
      _this.data.emergency_contact_number = argument.mobile;
      _this.data.emergency_contact_relation = argument.relation;
      _this.data.emergency_contact_email = argument.email;
      _this.data.emergency_contact_unit_no = argument.unit_no;
      _this.data.emergency_contact_street_1 = argument.street_1;
      _this.data.emergency_contact_street_2 = argument.street_2;
      _this.data.emergency_contact_postal_code = argument.postal_code;
      _this.data.emergency_country_name = argument.emergency_country_name;
    });

    EngineerEventBus.$on("update:address", (argument) => {
      _this.data.address_line_1 = argument.street_1;
      _this.data.address_line_2 = argument.street_2;
      _this.data.unit_number = argument.unit_no;
      _this.data.postal_code = argument.postal_code;
      _this.data.country_name = argument.country_name;
      _this.data.country = _this.lodash.toSafeInteger(argument.country) || null;
      _this.data.state = _this.lodash.toSafeInteger(argument.state) || null;
      _this.data.city = _this.lodash.toSafeInteger(argument.city) || null;
    });

    EngineerEventBus.$on("update:color", (argument) => {
      _this.data.color = argument.hexa;
    });
  },
};
