var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"gray-background-ss",attrs:{"md":"12"}},[_c('table',{staticClass:"width-100"},[(_vm.engineer.country_name)?_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500",staticStyle:{"width":"100px"},attrs:{"valign":"top"}},[(_vm.engineer.country_name == 'singapore')?[_vm._v(" Street 1 ")]:[_vm._v("Full Address")]],2),_c('td',{staticClass:"font-size-18 text-capitalize"},[(_vm.engineer.address_line_1)?[_vm._v(_vm._s(_vm.engineer.address_line_1))]:[_c('em',{staticClass:"text-muted"},[_vm._v("no street 1")])]],2)]):_vm._e(),(_vm.engineer.country_name == 'singapore')?[_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500"},[_vm._v(" Street 2 ")]),_c('td',{staticClass:"font-size-18 text-capitalize"},[(_vm.engineer.address_line_2)?[_vm._v(_vm._s(_vm.engineer.address_line_2))]:[_c('em',{staticClass:"text-muted"},[_vm._v("no street 2")])]],2)]),_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500"},[_vm._v(" Unit no. ")]),_c('td',{staticClass:"font-size-18 text-capitalize"},[(_vm.engineer.unit_number)?[_vm._v(_vm._s(_vm.engineer.unit_number))]:[_c('em',{staticClass:"text-muted"},[_vm._v("no unit no")])]],2)]),(
            _vm.engineer.postal_code != 'nil' &&
            _vm.engineer.postal_code != 'Nil' &&
            _vm.engineer.postal_code != 'NIL' &&
            _vm.engineer.postal_code != '000000'
          )?_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500"},[_vm._v(" Postal Code ")]),_c('td',{staticClass:"font-size-18 text-capitalize"},[(_vm.engineer.postal_code)?[_vm._v(_vm._s(_vm.engineer.postal_code))]:[_c('em',{staticClass:"text-muted"},[_vm._v("no postal code")])]],2)]):_vm._e()]:_vm._e(),(
          _vm.engineer.postal_code != 'nil' &&
          _vm.engineer.postal_code != 'Nil' &&
          _vm.engineer.postal_code != 'NIL' &&
          _vm.engineer.postal_code != '000000'
        )?_c('tr',[_c('td',{staticClass:"font-size-18 text-capitalize font-weight-500"},[_vm._v("Country")]),_c('td',{staticClass:"font-size-18 text-capitalize"},[(_vm.engineer.country_name)?[_vm._v(_vm._s(_vm.engineer.country_name))]:[_c('em',{staticClass:"text-muted"},[_vm._v("no country")])]],2)]):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }